import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import('@/views/Home.vue'),
      meta: {
        pageTitle: 'Home',
        breadcrumb: [
          {
            text: 'Home',
            active: true,
          },
        ],
      },
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/Login.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/king',
      name: 'project-king',
      component: () => import('@/views/ProjectPage.vue'),
      props: {
        project: 'king',
      },
      meta: {
        pageTitle: 'Project king',
        breadcrumb: [
          {
            text: 'Project Page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/king/reference',
      name: 'reference-page-king',
      component: () => import('@/views/ReferencePage.vue'),
      props: true,
      meta: {
        pageTitle: 'Report page',
        project: 'king',
        breadcrumb: [
          {
            to: { name: 'project-king' },
            text: 'Project page',
          },
          {
            text: 'Reference page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/report/king/:test',
      name: 'report-page-king',
      component: () => import('@/views/ReportPage.vue'),
      props: true,
      meta: {
        pageTitle: 'Report page',
        project: 'king',
        breadcrumb: [
          {
            to: { name: 'project-king' },
            text: 'Project page',
          },
          {
            text: 'Report page',
            active: true,
          },
        ],
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
